export const FORM_KEY = 'gnsense';
export const LANG = 'de';
export const YES = 'Ja';
export const NO = 'Nein';
export const NURSING_HOME_LOCATION_ID = '13780';

export const ADMIN_REP_TITLES = [
  {
    'id': '13835',
    'label': 'Frau'
  },
  {
    'id': '13836',
    'label': 'Herr'
  }
];
// representant-administratif-mesure-officiel
export const ADMIN_REP_OFFICIAL_MEASURES = [
  {
    'id': '13837',
    'label': 'Keine Beistandschaft'
  },
  {
    'id': '13838',
    'label': 'Kandidat unter Beistandschaft'
  },
  {
    'id': '13839',
    'label': 'Antrag Beistandschaft gestellt '
  }
];

//prestations-allocations-prestation-complementaires
export const BENEFITS_SERVICES = [
  {
    'id': '13812',
    'label': 'EL zuhause'
  },
  {
    'id': '13813',
    'label': 'EL Pflegeheim'
  }
];

export const CANDIDATE_TITLES = [
  {
    id: 13741,
    label: 'Frau'
  },
  {
    id: 13744,
    label: 'Herr'
  }
];

export const CANDIDATE_STATES = [
  {
    'id': '13748',
    'label': 'Argovie - Aargau'
  },
  {
    'id': '13749',
    'label': 'Appenzell Rhodes intérieures - Inner-Rhoden'
  },
  {
    'id': '13750',
    'label': 'Appenzell Rhodes extérieures - Ausser-Rhoden'
  },
  {
    'id': '13753',
    'label': 'Berne - Bern'
  },
  {
    'id': '13751',
    'label': 'Bâle Campagne - Basel Land'
  },
  {
    'id': '13758',
    'label': 'Bâle Ville - Basel Stadt'
  },
  {
    'id': '13752',
    'label': 'Fribourg - Freiburg'
  },
  {
    'id': '13756',
    'label': 'Genève - Genf'
  },
  {
    'id': '13754',
    'label': 'Glaris - Glarus'
  },
  {
    'id': '13755',
    'label': 'Grisons - Graubuenden'
  },
  {
    'id': '13757',
    'label': 'Jura'
  },
  {
    'id': '13759',
    'label': 'Lucerne -Luzern'
  },
  {
    'id': '13760',
    'label': 'Neuchâtel - Neuenburg'
  },
  {
    'id': '13761',
    'label': 'Nidwald - Nidwalden'
  },
  {
    'id': '13763',
    'label': 'Obwald - Obwalden'
  },
  {
    'id': '13762',
    'label': 'Saint-Gall - Sankt Gallen'
  },
  {
    'id': '13764',
    'label': 'Schaffhouse - Schaffausen'
  },
  {
    'id': '13765',
    'label': 'Soleure - Solothurn'
  },
  {
    'id': '13766',
    'label': 'Schwyz'
  },
  {
    'id': '13768',
    'label': 'Thurgovie - Thurgau'
  },
  {
    'id': '13767',
    'label': 'Ticino - Tessin'
  },
  {
    'id': '13769',
    'label': 'Uri'
  },
  {
    'id': '13770',
    'label': 'Vaud - Waadt'
  },
  {
    'id': '13771',
    'label': 'Valais - Vallis'
  },
  {
    'id': '13773',
    'label': 'Zug'
  },
  {
    'id': '13772',
    'label': 'Zurich - Zürich'
  }
];
//candidat-etat-civil
export const CANDIDATE_STATUS = [
  {
    'id': '13736',
    'label': 'Ledig'
  },
  {
    'id': '13737',
    'label': 'Verheiratet'
  },
  {
    'id': '13738',
    'label': 'Geschieden'
  },
  {
    'id': '13739',
    'label': 'Verwitwet'
  },
  {
    'id': '13740',
    'label': 'Getrennt'
  }
];


export const COMPLEMENTARY_INSURANCE_TYPES = [
  {
    'id': '13830',
    'label': 'Andere'
  },
  {
    'id': '13831',
    'label': 'Privat'
  },
  {
    'id': '13832',
    'label': 'Halb-privat'
  }
];


export const RELATIVE1_TITLES = [
  {
    'id': '13842',
    'label': 'Frau'
  },
  {
    'id': '13843',
    'label': 'Herr'
  }
];

export const RELATIVE2_TITLES = [
  {
    'id': '13833',
    'label': 'Frau'
  },
  {
    'id': '13834',
    'label': 'Herr'
  }
];


export const SITUATION_LOCATIONS = [
  {
    'id': '13776',
    'label': 'Wohnort'
  },
  {
    'id': '13778',
    'label': 'Spital'
  },
  // {
  //   'id': '13777',
  //   'label': 'AVAO'
  // },
  {
    'id': '13780',
    'label': 'Pflegeheim'
  }
];
export const SITUATION_LIVES_WITH = [
  {
    'id': '13791',
    'label': 'Allein'
  },
  {
    'id': '13792',
    'label': 'Mit Ehepartner'
  },
  {
    'id': '13793',
    'label': 'Mit Kind / Kindern'
  },
  {
    'id': '13794',
    'label': 'Haustiere'
  },
  {
    'id': '13795',
    'label': 'Andere'
  }
];

export const STAY_REQUEST_TYPES = [
  {
    'id': '13784',
    'label': 'Kurzaufenthalt'
  },
  {
    'id': '13779',
    'label': 'Langzeitaufenthalt'
  },
  {
    'id': '13781',
    'label': 'Tagesheim'
  },
  {
    'id': '13782',
    'label': 'Demenzabteilung'
  },
  {
    'id': '13783',
    'label': 'AVAO'
  }
];
export const STAY_REQUEST_ENTRIES = [
  {
    'id': '13787',
    'label': 'Sofort'
  },
  {
    'id': '13788',
    'label': '1 Monat'
  },
  {
    'id': '13789',
    'label': '3 Monate'
  },
  {
    'id': '13790',
    'label': '6 Monate'
  }
];
export const STAY_REQUEST_NURSING_HOME1 = [
  {
    'id': '13844',
    'label': 'Pflegeheim Maggenberg, Tafers',
    'stay_types': [13784, 13779, 13782]
  },
  {
    'id': '13845',
    'label': 'Pflegeheim Aergera, Giffers',
    'stay_types': [13784, 13779]
  },
  {
    'id': '13846',
    'label': 'Pflegeheim Bachmatte, Plaffeien',
    'stay_types': [13784, 13779]
  },
  {
    'id': '13847',
    'label': 'APH St. Martin, Tafers',
    'stay_types': [13784, 13779, 13783]
  },
  {
    'id': '15658',
    'label': 'Stiftung St. Wolfgang, Bösingen',
    'stay_types': [13784, 13779, 13781]
  },
  {
    'id': '13848',
    'label': 'Stiftung St. Wolfgang, Düdingen',
    'stay_types': [13784, 13779, 13781]
  },
  {
    'id': '15659',
    'label': 'Stiftung St. Wolfgang, Flamatt',
    'stay_types': [13784, 13779, 13781]
  },
  {
    'id': '15660',
    'label': 'Stiftung St. Wolfgang, Schmitten',
    'stay_types': [13784, 13779, 13781]
  }
];
export const STAY_REQUEST_NURSING_HOME2 = [
  {
    'id': '13849',
    'label': 'Pflegeheim Maggenberg, Tafers',
    'stay_types': [13784, 13779, 13782]
  },
  {
    'id': '13850',
    'label': 'Pflegeheim Aergera, Giffers',
    'stay_types': [13784, 13779]
  },
  {
    'id': '13851',
    'label': 'Pflegeheim Bachmatte, Plaffeien',
    'stay_types': [13784, 13779]
  },
  {
    'id': '13852',
    'label': 'APH St. Martin, Tafers',
    'stay_types': [13784, 13779, 13783]
  },
  {
    'id': '15661',
    'label': 'Stiftung St. Wolfgang, Bösingen',
    'stay_types': [13784, 13779, 13781]
  },
  {
    'id': '13853',
    'label': 'Stiftung St. Wolfgang, Düdingen',
    'stay_types': [13784, 13779, 13781]
  },
  {
    'id': '15662',
    'label': 'Stiftung St. Wolfgang, Flamatt',
    'stay_types': [13784, 13779, 13781]
  },
  {
    'id': '15663',
    'label': 'Stiftung St. Wolfgang, Schmitten',
    'stay_types': [13784, 13779, 13781]
  }
];


export const THERAPEUTIC_REP_TITLES = [
  {
    'id': '13840',
    'label': 'Frau'
  },
  {
    'id': '13841',
    'label': 'Herr'
  }
];
